import { toast } from 'react-toastify';
import { PayloadAction } from '@reduxjs/toolkit';
import { put, select, call, takeEvery } from 'typed-redux-saga/macro';

import HomeGateway from 'api/Home';
import { GatewayResponseStatus } from 'api/types/types';

import Utils from 'lib/Utils';
import NavActions from 'lib/NavActions';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { ISubmitUniqueCodeAndPhoneNumberParams } from 'redux/slices/home/types';

import { SagaWatcherReturnType } from 'sagas/types';

import Translate from 'translate/Translate';
import dayjs from 'dayjs';

export default function* watchSubmitUniqueCodeAndPhoneNumber(api: HomeGateway): SagaWatcherReturnType {
    yield takeEvery('home/homeSubmitUniqueCodeAndPhoneNumberAttempt', handleSubmitUniqueCodeAndPhoneNumber, api);
}

function* handleSubmitUniqueCodeAndPhoneNumber(api: HomeGateway, data: PayloadAction<ISubmitUniqueCodeAndPhoneNumberParams>) {
    const { code, phoneNumber, stage, startTime } = data.payload;
    const scanId = yield* select(Selectors.homeGetScanId);
    const skipUniqueCode = yield* select(Selectors.homeGetScanInfoCanSkipUniqueCode);

    if (!code && !skipUniqueCode) {
        yield put(Actions.homeSubmitUniqueCodeAndPhoneNumberFailure(Translate.t('uniqueCodeAndPhoneNumberError1')));
        return;
    }

    if (!phoneNumber || phoneNumber.length < 10 || phoneNumber.length > 12) {
        yield put(Actions.homeSubmitUniqueCodeAndPhoneNumberFailure(Translate.t('uniqueCodeErrorMessage2')));
        return;
    }

    if (!scanId) {
        yield put(Actions.homeSubmitUniqueCodeAndPhoneNumberFailure(''));
        yield put(Actions.homeSubmitOTPFailure('Sorry, we’re unable to verify your scan with your mobile number. Please try scanning your QR code again.'));
        NavActions.navToError();
        return;
    }

    const response = yield* call([api, api.submitUniqueCodeAndPhoneNumber], { scanId, code, phoneNumber });

    if (response.status === GatewayResponseStatus.Error) {
        if (response.name === '1500') {
            yield put(Actions.homeSubmitUniqueCodeAndPhoneNumberFailure(''));
            yield put(Actions.errorSetGeneralErrorPageMessage(Translate.t('uniqueCodeAndPhoneNumberErrorBlocked')));

            NavActions.navToError();
            return;
        }
        if (response.name === '3011') {
            toast.error(response.message || 'Something went wrong. Please try again');
            yield put(Actions.homeSubmitUniqueCodeAndPhoneNumberFailure(response.message));
            return;
        } if (response.name === '3012') {
            yield put(Actions.homeSubmitUniqueCodeAndPhoneNumberFailure(Translate.t('uniqueCodeErrorMessage2') || 'Something went wrong. Please try again'));
            return;
        } if (response.name === '3013') {
            yield put(Actions.homeSubmitUniqueCodeAndPhoneNumberFailure(''));
            NavActions.navToUnqualified();
        } else {
            if (response.name) {
                yield put(Actions.homeSubmitUniqueCodeAndPhoneNumberFailure(''));
                yield put(Actions.errorSetGeneralErrorPageMessage(Utils.Error.setGeneralErrorWithCode(response.name, response.message || 'Something went wrong please try again')));
            } else {
                yield put(Actions.homeSubmitUniqueCodeAndPhoneNumberFailure(''));
                yield put(Actions.errorSetGeneralErrorPageMessage(response.message || 'Something went wrong please try again'));
            }

            NavActions.navToError();
        }
        return;
    } if (response.status === GatewayResponseStatus.Success) {
        const isUnqiueCodeValid = response.data.isValid;

        if (isUnqiueCodeValid) {
            yield put(Actions.homeSubmitUniqueCodeAndPhoneNumberSuccess({ phoneNumber }));
            // v1 - NavActions.navToBondOtp();

            // Submit Time Tracker
            const endTime = dayjs().toISOString();
            const params = { stage, startTime, endTime };
            yield put(Actions.homeTrackStageTimeAttempt(params));

            // v2 - A global state is used to navigate between OTP and enter phone number screens since both screens are in the same container now
            yield put(Actions.homeDisplayOTPScreen(true));
            return;
        }

        if (!isUnqiueCodeValid) {
            toast.error(Translate.t('invalidUniqueCodeError'), {
                toastId: 'uniqueCodeToastError',
            });
            yield put(Actions.homeSubmitUniqueCodeAndPhoneNumberFailure(''));
            return;
        }

        NavActions.navToError();
    }
}
