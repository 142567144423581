import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IQrTypeEnum, ISurveyScanStageEnum, LanguageSelectionEnum } from 'api/HomeBase';
import Utils from 'lib/Utils';
import {
    ITrackScanParams,
    ITrackScanResponse,
    HomeReduxState,
    ISelectLanguageParams,
    ISetDOBParams,
    ISetPhoneNumberParams,
    ISubmitOTPParams,
    ISubmitBasicInfoParams,
    ISubmitBasicInfoResponse,
    IGetScanInfoResponse,
    ISelectLanguageAndDOBParams,
    ISubmitUniqueCodeAndPhoneNumberParams,
    ISubmitBasicInfoAndSurveyStatusParams,
    ISubmitBasicInfoAndSurveyStatusResponse,
    IGetCommonCopiesParams,
    IGetLanguagePackParams,
    IGetCommonCopiesResponse,
    IGetLanguagePackResponse,
    ITrackDOBParams,
    ITrackDisclaimerCheckboxEngagementParams,
    ITrackStageTimeParams,
} from './types';

const initialState: HomeReduxState = {
    actions: {
        trackScan: true,
        selectLanguage: false,
        setDOB: false,
        setPhoneNumber: false,
        submitOTP: false,
        resendOTP: false,
        submitBasicInfo: false,
        getScanInfo: false,
        selectLanguageAndDOB: false,
        submitUniqueCodeAndPhoneNumber: false,
        submitBasicInfoAndSurveyStatus: false,
        commonCopies: false,
        languagePack: false,
        trackDob: false,
        trackDisclaimerCheckboxEngagement: false,
        trackStageTime: false,
    },
    scanId: '',
    qrId: '',
    qrType: undefined,
    isTrialist: undefined,
    stage: ISurveyScanStageEnum.BondStWelcome,
    language: LanguageSelectionEnum.English,
    phoneNumber: '',
    dob: '',
    commonCopies: undefined,
    languagePack: {
        surveyDisclaimer: '',
        rewardsTitle: '',
        rewardsSubtitle: '',
        rewardsMsg: '',
        rewardsSendSmsMsg: '',
        uniqueCodeMessage: '',
        terms: '',
        privacyPolicy: '',
    },
    canSkipUniqueCode: false,
    canSkipReward: false,
    showIsFirstTimeBuyerOption: false,
    phoneNumberRepeated: false,
    surveyFlavor: '',
    displayOTPScreen: false,
    numberOfQuestions: 1,
    surveyName: '',
    error: {
        trackScan: '',
        selectLanguage: '',
        setDOB: '',
        setPhoneNumber: '',
        submitOTP: '',
        resendOTP: '',
        submitBasicInfo: '',
        getScanInfo: '',
        selectLanguageAndDOB: '',
        submitUniqueCodeAndPhoneNumber: '',
        submitBasicInfoAndSurveyStatus: '',
        commonCopies: '',
        languagePack: '',
        trackDob: '',
        trackDisclaimerCheckboxEngagement: '',
        trackStageTime: '',
    },
};

const homeSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {
        homeTrackDOBAttempt: (state, _action: PayloadAction<ITrackDOBParams>) => {
            state.actions.trackDob = true;
            state.error.trackDob = '';
        },
        homeTrackDOBFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.trackDob = false;
            if (action.payload) {
                state.error.trackDob = action.payload;
            }
        },
        homeTrackDOBSuccess: (state) => {
            state.actions.trackDob = false;
        },
        homeTrackDisclaimerCheckboxEngagementAttempt: (state, _action: PayloadAction<ITrackDisclaimerCheckboxEngagementParams>) => {
            state.actions.trackDisclaimerCheckboxEngagement = true;
            state.error.trackDisclaimerCheckboxEngagement = '';
        },
        homeTrackDisclaimerCheckboxEngagementSuccess: (state) => {
            state.actions.trackDisclaimerCheckboxEngagement = false;
        },
        homeTrackDisclaimerCheckboxEngagmentFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.trackDisclaimerCheckboxEngagement = false;

            if (action.payload) {
                state.error.trackDisclaimerCheckboxEngagement = action.payload;
            }
        },
        homeTrackStageTimeAttempt: (state, _action: PayloadAction<ITrackStageTimeParams>) => {
            state.actions.trackStageTime = true;
            state.error.trackStageTime = '';
        },
        homeTrackStageTimeSuccess: (state) => {
            state.actions.trackStageTime = false;
        },
        homeTrackStageTimeFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.trackStageTime = false;
            if (action.payload) {
                state.error.trackStageTime = action.payload;
            }
        },
        homeTrackScanAttempt: (state, _action: PayloadAction<ITrackScanParams>) => {
            state.actions.trackScan = true;
            state.error.trackScan = '';
        },
        homeTrackScanSuccess: (state, action: PayloadAction<ITrackScanResponse>) => {
            state.actions.trackScan = false;
            if (action.payload) {
                state.scanId = action.payload.scanId;
                state.qrType = action.payload.qrType;
                state.stage = action.payload.stage;
                state.canSkipReward = action.payload.canSkipReward;
                state.canSkipUniqueCode = action.payload.canSkipUniqueCode;
                state.showIsFirstTimeBuyerOption = action.payload.showIsFirstTimeBuyerOption;
            }
        },
        homeTrackScanFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.trackScan = false;
            if (action.payload) {
                state.error.trackScan = action.payload;
            }
        },
        homeSelectLanguageAttempt: (state, _action: PayloadAction<ISelectLanguageParams>) => {
            state.actions.selectLanguage = true;
            state.error.selectLanguage = '';
        },
        homeSelectLanguageSuccess: (state, action: PayloadAction<ISelectLanguageParams>) => {
            state.actions.selectLanguage = false;
            if (action.payload) {
                state.language = action.payload.lang;
            }
        },
        homeSelectLanguageFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.selectLanguage = false;
            if (action.payload) {
                state.error.selectLanguage = action.payload;
            }
        },
        homeResetSelectLanguage: (state) => {
            state.actions.selectLanguage = false;
            state.error.selectLanguage = '';
        },
        homeSetDOBAttempt: (state, _action: PayloadAction<ISetDOBParams>) => {
            state.actions.setDOB = true;
            state.error.setDOB = '';
        },
        homeSetDOBSuccess: (state, action: PayloadAction<ISetDOBParams>) => {
            state.actions.setDOB = false;
            if (action.payload) {
                state.dob = action.payload.dob;
            }
        },
        homeSetDOBFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.setDOB = false;
            if (action.payload) {
                state.error.setDOB = action.payload;
            }
        },
        homeResetSetDOB: (state) => {
            state.actions.setDOB = false;
            state.error.setDOB = '';
        },
        homeSetPhoneNumberAttempt: (state, _action: PayloadAction<ISetPhoneNumberParams>) => {
            state.actions.setPhoneNumber = true;
            state.error.setPhoneNumber = '';
        },
        homeSetPhoneNumberSuccess: (state, action: PayloadAction<ISetPhoneNumberParams>) => {
            state.actions.setPhoneNumber = false;
            if (action.payload) {
                state.phoneNumber = action.payload.phoneNumber;
            }
        },
        homeSetPhoneNumberFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.setPhoneNumber = false;
            if (action.payload) {
                state.error.setPhoneNumber = action.payload;
            }
        },
        homeResetSetPhoneNumber: (state) => {
            state.actions.setPhoneNumber = false;
            state.error.setPhoneNumber = '';
        },
        homeSubmitOTPAttempt: (state, _action: PayloadAction<ISubmitOTPParams>) => {
            state.actions.submitOTP = true;
            state.error.submitOTP = '';
        },
        homeSubmitOTPSuccess: (state) => {
            state.actions.submitOTP = false;
        },
        homeSubmitOTPFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.submitOTP = false;
            if (action.payload) {
                state.error.submitOTP = action.payload;
            }
        },
        homeResetSubmitOTP: (state) => {
            state.actions.submitOTP = false;
            state.error.submitOTP = '';
        },
        homeResendOTPAttempt: (state) => {
            state.actions.resendOTP = true;
            state.error.resendOTP = '';
        },
        homeResendOTPSuccess: (state) => {
            state.actions.resendOTP = false;
        },
        homeResendOTPFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.resendOTP = false;
            if (action.payload) {
                state.error.resendOTP = action.payload;
            }
        },
        homeSubmitBasicInfoAttempt: (state, _action: PayloadAction<ISubmitBasicInfoParams>) => {
            state.actions.submitBasicInfo = true;
            state.error.submitBasicInfo = '';
        },
        homeSubmitBasicInfoSuccess: (state, action: PayloadAction<ISubmitBasicInfoResponse>) => {
            state.actions.submitBasicInfo = false;
            if (action.payload) {
                state.qrType = action.payload.qrType;
                state.isTrialist = action.payload.isTrialist;
            }
        },
        homeSubmitBasicInfoFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.submitBasicInfo = false;
            if (action.payload) {
                state.error.submitBasicInfo = action.payload;
            }
        },
        homeGetScanInfoAttempt: (state) => {
            state.actions.getScanInfo = true;
            state.error.getScanInfo = '';
        },
        homeGetScanInfoSuccess: (state, action: PayloadAction<IGetScanInfoResponse>) => {
            state.actions.getScanInfo = false;
            const {
                qrId,
                language,
                phoneNumber,
                dob,
                stage,
                qrType,
                canSkipUniqueCode,
                canSkipReward,
                showIsFirstTimeBuyerOption,
                phoneNumberRepeated,
                surveyFlavor,
                surveyName,
                numberOfQuestions,
            } = action.payload;

            if (qrId) state.qrId = qrId;
            if (qrType !== undefined) state.qrType = qrType;
            if (language) state.language = language;
            if (phoneNumber) state.phoneNumber = phoneNumber;
            if (dob) state.dob = dob;
            if (stage) state.stage = stage;

            if (canSkipUniqueCode) state.canSkipUniqueCode = canSkipUniqueCode;
            if (canSkipReward) state.canSkipReward = canSkipReward;
            if (showIsFirstTimeBuyerOption) state.showIsFirstTimeBuyerOption = showIsFirstTimeBuyerOption;
            if (phoneNumberRepeated) state.phoneNumberRepeated = phoneNumberRepeated;
            if (surveyFlavor) state.surveyFlavor = surveyFlavor;
            if (surveyName) state.surveyName = surveyName;
            if (numberOfQuestions) state.numberOfQuestions = numberOfQuestions;
        },
        homeGetScanInfoFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getScanInfo = false;
            if (action.payload) {
                state.error.getScanInfo = action.payload;
            }
        },
        homeSetScanId: (state, action: PayloadAction<string>) => {
            state.scanId = action.payload;
        },
        homeSelectLanguageAndDOBAttempt: (state, _action: PayloadAction<ISelectLanguageAndDOBParams>) => {
            state.actions.selectLanguageAndDOB = true;
            state.error.selectLanguageAndDOB = '';
        },
        homeSelectLanguageAndDOBSuccess: (state, action: PayloadAction<ISelectLanguageAndDOBParams>) => {
            state.actions.selectLanguageAndDOB = false;
            if (action.payload) {
                state.dob = action.payload.dob;
                state.language = action.payload.lang;
            }
        },
        homeSelectLanguageAndDOBFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.selectLanguageAndDOB = false;
            if (action.payload) {
                state.error.selectLanguageAndDOB = action.payload;
            }
        },
        homeResetSelectLanguageAndDOB: (state) => {
            state.actions.selectLanguageAndDOB = false;
            state.error.selectLanguageAndDOB = '';
        },
        homeSubmitUniqueCodeAndPhoneNumberAttempt: (state, _action: PayloadAction<ISubmitUniqueCodeAndPhoneNumberParams>) => {
            state.actions.submitUniqueCodeAndPhoneNumber = true;
            state.error.submitUniqueCodeAndPhoneNumber = '';
        },
        homeSubmitUniqueCodeAndPhoneNumberSuccess: (state, action: PayloadAction<ISetPhoneNumberParams>) => {
            state.actions.submitUniqueCodeAndPhoneNumber = false;
            if (action.payload) {
                state.phoneNumber = action.payload.phoneNumber;
            }
        },
        homeSubmitUniqueCodeAndPhoneNumberFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.submitUniqueCodeAndPhoneNumber = false;
            if (action.payload) {
                state.error.submitUniqueCodeAndPhoneNumber = action.payload;
            }
        },
        homeResetSubmitUniqueCodeAndPhoneNumber: (state) => {
            state.actions.submitUniqueCodeAndPhoneNumber = false;
            state.error.submitUniqueCodeAndPhoneNumber = '';
        },
        homeSubmitBasicInfoAndSurveyStatusAttempt: (state, _action: PayloadAction<ISubmitBasicInfoAndSurveyStatusParams>) => {
            state.actions.submitBasicInfoAndSurveyStatus = true;
            state.error.submitBasicInfoAndSurveyStatus = '';
        },
        homeSubmitBasicInfoAndSurveyStatusSuccess: (state, action: PayloadAction<ISubmitBasicInfoAndSurveyStatusResponse>) => {
            state.actions.submitBasicInfoAndSurveyStatus = false;
            if (action.payload) {
                state.qrType = action.payload.qrType;
                state.isTrialist = action.payload.isTrialist;
            }
        },
        homeSubmitBasicInfoAndSurveyStatusFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.submitBasicInfoAndSurveyStatus = false;
            if (action.payload) {
                state.error.submitBasicInfoAndSurveyStatus = action.payload;
            }
        },
        homeGetCommonCopiesAttempt: (state, _action: PayloadAction<IGetCommonCopiesParams>) => {
            state.actions.commonCopies = true;
            state.error.commonCopies = '';
        },
        homeGetCommonCopiesSuccess: (state, action: PayloadAction<IGetCommonCopiesResponse>) => {
            const { qrType } = action.payload;

            if (qrType === IQrTypeEnum.AnswerOnce) {
                const {
                    homeTitleEn,
                    homeTitleMs,
                    homeTitleZh,
                    homeSelectLangEn,
                    homeSelectLangMs,
                    homeSelectLangZh,
                    disclaimerEn,
                    disclaimerMs,
                    disclaimerZh,
                } = action.payload;

                state.commonCopies = {
                    homeTitleEn,
                    homeTitleMs,
                    homeTitleZh,
                    homeSelectLangEn,
                    homeSelectLangMs,
                    homeSelectLangZh,
                    disclaimerEn,
                    disclaimerMs,
                    disclaimerZh,
                };
            } if (qrType === IQrTypeEnum.AnswerMultiple) {
                const {
                    homeSubtitleEn = '',
                    homeSubtitleMs = '',
                    homeSubtitleZh = '',
                    termsEn = '',
                    termsMs = '',
                    termsZh = '',
                    privacyPolicyEn = '',
                    privacyPolicyMs = '',
                    privacyPolicyZh = '',
                    ...restOfBondStCommonCopies
                } = action.payload;

                state.commonCopies = {
                    homeSubtitleEn,
                    homeSubtitleMs,
                    homeSubtitleZh,
                    termsEn,
                    termsMs,
                    termsZh,
                    privacyPolicyEn,
                    privacyPolicyMs,
                    privacyPolicyZh,
                    ...restOfBondStCommonCopies,
                };
            }
            state.actions.commonCopies = false;
        },
        homeGetCommonCopiesFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.commonCopies = false;
            if (action.payload) {
                state.error.commonCopies = action.payload;
            }
        },
        homeGetLanguagePackAttempt: (state, _action: PayloadAction<IGetLanguagePackParams>) => {
            state.actions.languagePack = true;
            state.error.languagePack = '';
        },
        homeGetLanguagePackSuccess: (state, action: PayloadAction<IGetLanguagePackResponse>) => {
            const { qrType } = action.payload;

            if (qrType === IQrTypeEnum.AnswerOnce) {
                const {
                    terms = '',
                    privacyPolicy = '',
                    surveyDisclaimer = '',
                    rewardsTitle = '',
                    rewardsSubtitle = '',
                    rewardsMsg = '',
                    rewardsSendSmsMsg = '',
                } = action.payload;

                state.languagePack = {
                    terms,
                    privacyPolicy,
                    surveyDisclaimer,
                    rewardsTitle,
                    rewardsSubtitle,
                    rewardsMsg,
                    rewardsSendSmsMsg,
                };
            } if (qrType === IQrTypeEnum.AnswerMultiple) {
                const {
                    uniqueCodeMessage = '',
                    surveyDisclaimer = '',
                    rewardsTitle = '',
                    rewardsSubtitle = '',
                    rewardsMsg = '',
                    rewardsSendSmsMsg = '',
                } = action.payload;

                state.languagePack = {
                    uniqueCodeMessage,
                    surveyDisclaimer,
                    rewardsTitle,
                    rewardsSubtitle,
                    rewardsMsg,
                    rewardsSendSmsMsg,
                };
            }
            state.actions.languagePack = false;
        },
        homeGetLanguagePackFailure: (state, action: PayloadAction<string | undefined>) => {
            if (action.payload) {
                state.error.languagePack = action.payload;
            }
            state.actions.languagePack = false;
        },
        homeSetLanguage: (state, action: PayloadAction<LanguageSelectionEnum>) => {
            state.language = action.payload;
            if (action.payload) Utils.User.setUserLanguage(LanguageSelectionEnum[action.payload]);
        },
        homeDisplayOTPScreen: (state, action: PayloadAction<boolean>) => {
            state.displayOTPScreen = action.payload;
        },
        homeHardResetPhoneNumber: (state) => {
            state.phoneNumber = '';
        },
    },
});

export type HomeState = typeof initialState;

export default {
    actions: homeSlice.actions,
    reducers: homeSlice.reducer,
};
