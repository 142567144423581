import { put, select, call, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import HomeGateway from 'api/Home';
import NavActions from 'lib/NavActions';
import Translate from 'translate/Translate';

import Actions from 'redux/Actions';
import { ISubmitOTPParams } from 'redux/slices/home/types';
import { GatewayResponseStatus } from 'api/types/types';
import { PayloadAction } from '@reduxjs/toolkit';
import Selectors from 'redux/Selectors';
import { toast } from 'react-toastify';
import { IQrTypeEnum } from 'api/HomeBase';
import dayjs from 'dayjs';

export default function* watchSubmitOTP(api: HomeGateway): SagaWatcherReturnType {
    yield takeEvery('home/homeSubmitOTPAttempt', handleSubmitOTP, api);
}

function* handleSubmitOTP(api: HomeGateway, data: PayloadAction<ISubmitOTPParams>) {
    const { otp, stage, startTime } = data.payload;
    const scanId = yield* select(Selectors.homeGetScanId);

    if (!otp) {
        yield put(Actions.homeSubmitOTPFailure(Translate.t('otpSubmitError')));
        return;
    }

    if (!scanId) {
        yield put(Actions.homeSubmitOTPFailure('Sorry, we’re unable to verify your scan with your OTP code. Please try scanning your QR code again.'));
        NavActions.navToError();
        return;
    }

    const response = yield* call([api, api.submitOTP], { scanId, otp });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.homeSubmitOTPFailure(Translate.t('otpSubmitError')));
        toast.error(Translate.t('otpSubmitError') || 'Something went wrong. Please try again');
    } if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.homeSubmitOTPSuccess());
        yield put(Actions.homeGetScanInfoAttempt());

        if (!response.data.qrType) {
            NavActions.navToError();
        } if (response.data.qrType === IQrTypeEnum.AnswerMultiple) {
            // v1 - NavActions.navToBasicInfoAndSurveyStatus();
            // v2 - Basic info is in Survey Screen V2 now and require conditional rendering to control the displayed screen
            // Reset survey questions state before navigate to survey question screen
            yield put(Actions.homeDisplayOTPScreen(false));
            yield put(Actions.surveySetSurveyHasBasicInfo(false));
            yield put(Actions.surveySetProgress(0));

            // Submit Time Tracker
            const endTime = dayjs().toISOString();
            const params = { stage, startTime, endTime };
            yield put(Actions.homeTrackStageTimeAttempt(params));

            NavActions.navToSurveyV2();
            return;
        }

        NavActions.navToBasicInfo();
    }
}
