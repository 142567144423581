import Gateway from './types/Gateway';
import { GatewayResponse } from './types/types';

export interface TrackScanParams {
    qrId: string;
    ipAddress: string;
    deviceType: number;
    os: number;
    mobileModel?: string;
    browser?: string;
}

export enum ISurveyTypeEnum {
    Standard = 1,
    Trialist = 2,
    RepeatedBuyer = 3,
}

export enum IDeviceTypeEnum {
    Mobile = 1,
    Tablet = 2,
    Desktop = 3,
    Others = 4,
}

export enum IDeviceOsEnum {
    Android = 1,
    iOS = 2,
    Windows = 3,
    MacOs = 4,
    Others = 5,
}

export enum IQrTypeEnum {
    AnswerOnce = 0,
    AnswerMultiple = 1,
}

export interface TrackScanResponse {
    scanId: string;
    qrType: IQrTypeEnum;
    stage: ISurveyScanStageEnum;
    canSkipUniqueCode: boolean;
    canSkipReward: boolean;
    showIsFirstTimeBuyerOption: boolean;
}

export enum LanguageSelectionEnum {
    English = 1,
    Malay = 2,
    Chinese = 3,
}

export interface SelectLanguageParams {
    scanId: string;
    lang: LanguageSelectionEnum;
}

export interface SetDOBParams {
    scanId: string;
    dob: string;
}

export interface SetPhoneNumberParams {
    scanId: string;
    phoneNumber: string;
}

export interface SubmitUniqueCodeParams {
    scanId: string;
    code: string;
}

export interface SubmitUniqueCodeResponse {
    isValid: boolean;
}

export interface SubmitOTPParams {
    scanId: string;
    otp: string;
}

export interface SubmitOTPResponse {
    qrType: IQrTypeEnum;
}

export interface ResendOTPParams {
    scanId: string;
}

export enum INicotineBrandEnum {
    BensonAndHedges = 1,
    BondStreet = 2,
    Chesterfield = 3,
    Dunhill = 4,
    Fiit = 5,
    Heets = 6,
    Kent = 7,
    KYO = 8,
    LAndM = 9,
    LD = 10,
    Marlboro = 11,
    Mevius = 12,
    PallMall = 13,
    PeterStuyvesant = 14,
    Rothmans = 15,
    Sampoerna = 16,
    Winston = 17,
    Others = 18,
}

export enum IFlavorEnum {
    MarlboroRedBox = 1101,
    MarlboroRedSoft = 1102,
    MarlboroGold = 1103,
    MarlboroBlackMenthol = 1104,
    MarlboroDoubleBurst = 1105,
    MarlboroIceBlast = 1106,
    MarlboroMenthol = 1107,
    MarlboroTropicalSplash = 1108,
    MarlboroSummerSplash = 1109,
    BensonAndHedges = 101,
    BondStreetRed = 201,
    BondStreetBlue = 202,
    BondStreetGreen = 204,
    ChesterfieldCharcoalFilter = 301,
    ChesterfieldRed = 302,
    ChesterfieldBlue = 303,
    ChesterfieldMenthol = 304,
    ChesterfieldPurple = 305,
    DunhillClassicRed = 401,
    DunhillRed = 402,
    DunhillBlue = 403,
    DunhillSwitch = 404,
    DunhillMenthol = 405,
    DunhillMentholBoost = 406,
    DunhillMix = 407,
    DunhillBotanicalBurst = 409,
    DunhillSpectra = 410,
    FiitRegular = 501,
    FiitAlpine = 502,
    FiitCrisp = 503,
    HeetsAmber = 601,
    HeetsBronze = 602,
    HeetsRusset = 603,
    HeetsSienna = 604,
    HeetsBlackGreen = 605,
    HeetsBlue = 606,
    HeetsGreenZing = 607,
    HeetsPurple = 608,
    HeetsTurquoise = 609,
    KentRedSoft = 701,
    KentFutura = 702,
    KyoOriginal = 801,
    KyoSilver = 802,
    KyoRed = 803,
    KyoSwitch = 804,
    LAndMRed = 901,
    LAndMCharcoal = 902,
    LAndMMenthol = 903,
    LDRed = 1001,
    LDBlue = 1002,
    LDMenthol = 1003,
    LDPurple = 1004,
    LDRedFlow = 1005,
    LDZoom = 1006,
    LDGreenFlow =1007,
    LDBanana = 1008,
    MeviusCharcoalFilter = 1201,
    MeviusSkyBlue = 1202,
    MeviusWindBlue = 1203,
    MeviusElite = 1204,
    MeviusLSSYellow = 1205,
    MeviusMentholBox = 1206,
    MeviusMentholDuo = 1207,
    MeviusMentholSoft = 1208,
    MeviusNovaWhite = 1209,
    MeviusKiwami = 1210,
    MeviusQuatroMix = 1211,
    PallMallRed = 1301,
    PallMallBlue = 1302,
    PallMallIce = 1303,
    PallMallMenthol = 1304,
    PallMallMint = 1305,
    PeterStuyvesantRed = 1401,
    PeterStuyvesantBlue = 1402,
    PeterStuyvesantMenthol = 1403,
    PeterStuyvesantRemix = 1404,
    RothmansRed = 1501,
    RothmansBlue = 1502,
    RothmansCharcoalFilter = 1503,
    RothmansMenthol = 1504,
    RothmansPurple = 1505,
    RothmansBoost = 1506,
    RothmansFujiBoost = 1507,
    RothmansPowerBoost = 1508,
    SampoernaARed = 1601,
    SampoernaAMenthol = 1602,
    WinstonRed = 1701,
    WinstonBlue = 1703,
    WinstonExcelDuo = 1704,
}
export enum ISticksSmokedEnum {
    LessThanOnePerWeek = 11,
    MoreThanOnePerWeek = 12,
}

export enum IStateEnum {
    NotSet = 0,
    Johor = 1,
    Kedah = 2,
    Kelantan = 3,
    Malacca = 4,
    NegeriSembilan = 5,
    Pahang = 6,
    Penang = 7,
    Perak = 8,
    Perlis = 9,
    Sabah = 10,
    Sarawak = 11,
    Selangor = 12,
    Terengganu = 13,
    KualaLumpur = 14,
    Labuan = 15,
    Putrajaya = 16,
}

export enum IGenderEnum {
    NotSet = 0,
    Male = 1,
    Female = 2,
}

export enum IEthnicityEnum {
    NotSet = 0,
    Malay = 1,
    Chinese = 2,
    Indian = 3,
    Others = 4,
}

export enum IProfessionEnum {
    // AdvertisingJournalismPR = 41,
    // GovernmentOfficerHealthcareTobacco = 42,
    // MarketResearch = 43,
    // OtherProfessions = 44,
    BlockedProfessions = 51,
    NotBlockedProfessions = 52,
}

export interface SubmitBasicInfoParams {
    scanId: string;
    gender: IGenderEnum;
    state: IStateEnum;
    ethnicity: IEthnicityEnum;
    profession: IProfessionEnum;
    nicotineBrand: INicotineBrandEnum;
    smokeFlavour: IFlavorEnum;
    sticksSmoked: ISticksSmokedEnum;
    currentlyBreastfeeding: boolean;
}

export interface SubmitBasicInfoResponse {
    qrType: IQrTypeEnum;
    isTrialist: boolean;
}

export enum ISurveyScanStageEnum {
    MarlboroWelcome = 101,
    MarlboroSelectingDob = 102,
    MarlboroSelectingPhoneNumber = 103,
    MarlboroAlreadyAnswered = 501,
    MarlboroEnteringOtp = 104,
    MarlboroSelectingBasicInfo = 105,
    MarlboroFailedBasicInfo = 502,
    MarlboroAnsweringSurvey = 106,
    MarlboroRewardScreen = 107,

    BondStWelcome = 201,
    BondStUniqueCodeAndPhone = 202,
    BondStFailedUniqueCode = 601,
    BondStOtp = 203,
    BondStBasicInfo = 204,
    BondStFailedBasicInfo = 602,
    BondStAnsweringSurvey = 205,
    BondStReward = 206,
}

export interface SubmitSurveyStatusParams {
    scanId: string;
    isTrialist: boolean;
}

export interface GetScanInfoParams {
    scanId: string;
}

export interface GetScanInfoResponse {
    qrId: string;
    hasCompletedSurvey: boolean;
    stage: ISurveyScanStageEnum;
    qrType: IQrTypeEnum;
    language?: LanguageSelectionEnum;
    phoneNumber?: string;
    dob?: string;
    canSkipUniqueCode: boolean;
    canSkipReward: boolean;
    showIsFirstTimeBuyerOption: boolean;
    phoneNumberRepeated: boolean;
    surveyFlavor?: string;
    numberOfQuestions: number;
    surveyName?: string;
}

export interface SelectLanguageAndDOBParams {
    scanId: string;
    lang: LanguageSelectionEnum;
    dob: string;
}

export interface SubmitUniqueCodeAndPhoneNumberParams {
    scanId: string;
    code: string;
    phoneNumber: string;
}

export interface SubmitBasicInfoAndSurveyStatusParams {
    scanId: string;
    gender?: IGenderEnum;
    state: IStateEnum;
    ethnicity: IEthnicityEnum;
    profession: IProfessionEnum;
    nicotineBrand: INicotineBrandEnum;
    smokeFlavour?: IFlavorEnum;
    sticksSmoked: ISticksSmokedEnum;
    currentlyBreastfeeding?: boolean;
    isTrialist: boolean;
}

export interface SubmitBasicInfoAndSurveyStatusResponse {
    qrType: IQrTypeEnum;
    isTrialist: boolean;
}

export interface GetCommonCopiesParams {
    qrType: IQrTypeEnum;
    qrId: string;
}

export interface GetCommonCopiesResponse {
    homeTitleEn: string;
    homeTitleMs: string;
    homeTitleZh: string;
    homeSelectLangEn: string;
    homeSelectLangMs: string;
    homeSelectLangZh: string;
    disclaimerEn: string;
    disclaimerMs: string;
    disclaimerZh: string;
    homeSubtitleEn?: string;
    homeSubtitleMs?: string;
    homeSubtitleZh?: string;
    termsEn?: string;
    termsMs?: string;
    termsZh?: string;
    privacyPolicyEn?: string;
    privacyPolicyMs?: string;
    privacyPolicyZh?: string;
    stepOneCopyEn?: string;
    stepOneCopyBm?: string;
    stepOneCopyCh?: string;
    stepOneMediaPath?: string;
    stepTwoCopyEn?: string;
    stepTwoCopyBm?: string;
    stepTwoCopyCh?: string;
}

export interface GetLanguagePackParams {
    scanId: string;
    qrType: IQrTypeEnum;
    lang: LanguageSelectionEnum;
}

export interface GetLanguagePackResponse {
    surveyDisclaimer: string;
    rewardsTitle: string;
    rewardsSubtitle: string;
    rewardsMsg: string;
    rewardsSendSmsMsg: string;
    uniqueCodeMessage?: string;
    terms?: string;
    privacyPolicy?: string;
}

export interface TrackDisclaimerEngagementCheckboxParams {
    scanId: string;
    tncAndPrivacyPolicyCheckbox: boolean;
    notFromTheseIndustriesCheckbox: boolean;
}

export interface TrackStageTimeParams {
    scanId: string;
    stage: number;
    startTime: string;
    endTime: string;
}

export abstract class IHomeGateway extends Gateway {
    abstract trackScan(params: TrackScanParams): GatewayResponse<TrackScanResponse>;

    abstract selectLanguage(params: SelectLanguageParams): GatewayResponse<null>;

    abstract setDOB(params: SetDOBParams): GatewayResponse<null>;

    abstract setPhoneNumber(params: SetPhoneNumberParams): GatewayResponse<null>;

    abstract submitUniqueCode(params: SubmitUniqueCodeParams): GatewayResponse<SubmitUniqueCodeResponse>;

    abstract submitOTP(params: SubmitOTPParams): GatewayResponse<SubmitOTPResponse>;

    abstract resendOTP(params: ResendOTPParams): GatewayResponse<null>;

    abstract submitBasicInfo(params: SubmitBasicInfoParams): GatewayResponse<SubmitBasicInfoResponse>;

    abstract submitSurveyStatus(params: SubmitSurveyStatusParams): GatewayResponse<null>;

    abstract getScanInfo(params: GetScanInfoParams): GatewayResponse<GetScanInfoResponse>;

    abstract selectLanguageAndDOB(params: SelectLanguageAndDOBParams): GatewayResponse<null>;

    abstract submitUniqueCodeAndPhoneNumber(params: SubmitUniqueCodeAndPhoneNumberParams): GatewayResponse<SubmitUniqueCodeResponse>;

    abstract submitBasicInfoAndSurveyStatus(params: SubmitBasicInfoAndSurveyStatusParams): GatewayResponse<SubmitBasicInfoAndSurveyStatusResponse>;

    abstract getCommonCopies(params: GetCommonCopiesParams): GatewayResponse<GetCommonCopiesResponse>;

    abstract getLanguagePack(params: GetLanguagePackParams): GatewayResponse<GetLanguagePackResponse>;

    abstract trackDisclaimerCheckboxEngagement(params: TrackDisclaimerEngagementCheckboxParams): GatewayResponse<null>

    abstract trackStageTime(params: TrackStageTimeParams): GatewayResponse<null>
}
