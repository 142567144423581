import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import Selectors from 'redux/Selectors';
import { AppDispatch, RootState } from 'redux/store';

import Text from 'components/Text';
import ProgressBar from 'components/ProgressBar';
import SurveyFooter from 'components/SurveyFooter';

import { ISurvey } from 'entities/surveys';

import NavActions from 'lib/NavActions';

import { ILanguagePack, ITrackStageTimeParams } from 'redux/slices/home/types';

import Translate from 'translate/Translate';

import dayjs from 'dayjs';
import { ISurveyScanStageEnum } from 'api/HomeBase';
import Actions from 'redux/Actions';
import SurveyQuestions from './SurveyQuestions';
import BasicInfoAndSurveyStatus from './BasicInfoAndSurveyStatus';

import { ContainerStyles, ItemStyles } from '../../surveys/styles';

interface SurveyScreenProps {
    basicInfoAnswered: boolean;
    surveyProgress: number;
    surveyData: ISurvey | null;
    languagePack: ILanguagePack;
    surveyQuestionsLength: number;
    trackStageTime: (params: ITrackStageTimeParams) => void;
}

const SurveyScreen = ({
    basicInfoAnswered,
    surveyProgress,
    surveyData,
    languagePack,
    surveyQuestionsLength,
    trackStageTime,
}: SurveyScreenProps): JSX.Element => {
    const maxQuestions = surveyQuestionsLength;
    const progressMaxIdx = maxQuestions + 1; // Added one because basic info question is included in the survey

    const progressValue = surveyProgress + (basicInfoAnswered ? 2 : 1);
    const progressFraction = ((progressValue) / progressMaxIdx);
    const progressPercentage = (progressFraction) * 100;

    const renderBody = () => {
        if (!basicInfoAnswered) return <BasicInfoAndSurveyStatus />;

        return <SurveyQuestions />;
    };

    const submitClickHandler = () => {
        if (surveyData && surveyData.questions && surveyData.questions.length > 0) {
            const notAnswered = surveyData.questions.find(item => item.answered === false);

            if (notAnswered) {
                toast.error(Translate.t('surveyFillAllSurveyErrorMessage'));
                return;
            }

            // Submit Time Tracker
            const endTime = dayjs().toISOString();
            const params = { stage: ISurveyScanStageEnum.BondStAnsweringSurvey, startTime, endTime };
            trackStageTime(params);

            NavActions.navToRewards();
        }
    };

    const renderSurveyName = () => {
        return Translate.t('surveyTitle');
    };

    const [startTime, setStartTime] = useState('');
    useEffect(() => {
        if (basicInfoAnswered) {
            const time = dayjs();
            setStartTime(time.toISOString());
        }
    }, [basicInfoAnswered]);

    return (
        <Container>
            <ProgressBar percentage={progressPercentage} />
            <div style={ContainerStyles.titleAndDescContainer}>
                <Text css={ItemStyles.title}>{renderSurveyName()}</Text>
                <Text css={ItemStyles.desc}>{languagePack?.surveyDisclaimer}</Text>
            </div>
            {renderBody()}
            <SurveyFooter
                questions={surveyData?.questions ?? []}
                onSubmit={submitClickHandler}
            />
        </Container>
    );
};

const mapStateToProps = (state: RootState) => ({
    basicInfoAnswered: Selectors.surveyGetSurveyHasBasicInfo(state),
    surveyData: Selectors.surveyGetAllSurveys(state),
    surveyQuestionsLength: Selectors.homeGetSurveyQuestionsLength(state),
    surveyProgress: Selectors.surveyGetSurveyProgress(state),
    languagePack: Selectors.homeGetLanguagePack(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    trackStageTime: (params: ITrackStageTimeParams) => dispatch(Actions.homeTrackStageTimeAttempt(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SurveyScreen);

const Container = styled.div`
    padding: 20px;
    height: 100%;
    position: relative;
`;
