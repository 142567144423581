import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga4';
import { Spinner } from 'reactstrap';

import Actions from 'redux/Actions';
import Selectors from 'redux/Selectors';
import { ISubmitOTPParams } from 'redux/slices/home/types';
import { AppDispatch, RootState } from 'redux/store';

import Translate from 'translate/Translate';

import Button from 'components/Button';
import Input from 'components/Input';

import OTPLOV from 'lib/LOVs/OTP';
import TimeTracker from 'components/TimeTracker';
import { ISurveyScanStageEnum } from 'api/HomeBase';
import dayjs from 'dayjs';
import { ContainerStyles, ItemStyles } from './styles';

interface BondOTPProps {
    submittingOTP: boolean;
    submitOTPError: string;
    resendingOTP: boolean;
    phoneNumber: string;
    submitOTP: (params: ISubmitOTPParams) => void;
    resendOTP: () => void;
    resetSubmitOTP: () => void;
    isOTPReady: boolean;
    displayOTP: (param: boolean) => void;
    clearPhoneNumber: () => void;
}

const BondOTP = (props: BondOTPProps): JSX.Element => {
    const {
        submittingOTP,
        submitOTPError,
        resendingOTP,
        phoneNumber,
        submitOTP,
        resendOTP,
        resetSubmitOTP,
        displayOTP,
        isOTPReady,
        clearPhoneNumber,
    } = props;

    const [code, setCode] = useState('');
    const [resendDisabled, setResendDisabled] = useState(true);
    const [timer, setTimer] = useState(30);

    useEffect(() => {
        ReactGA.gtag('event', 'page_view', {
            page_title: window.location.pathname,
            page_location: window.location.pathname,
        });
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        let intervalId: NodeJS.Timer;

        if (isOTPReady && resendDisabled === true && timer > 0) {
            let interval = timer * 1;
            timeoutId = setTimeout(() => { setResendDisabled(false); }, timer * 1000);
            intervalId = setInterval(() => {
                setTimer(interval - 1);
                interval -= 1;
            }, 1000);
        }

        return () => {
            clearTimeout(timeoutId);
            clearInterval(intervalId);
        };
    }, [resendDisabled, isOTPReady]);

    const handleProceedClick = () => {
        if (code.length === 6) {
            submitOTP({ otp: code, stage: ISurveyScanStageEnum.BondStOtp, startTime });
        }
    };

    const handleResendOTPClick = () => {
        resetSubmitOTP();
        setTimer(30);
        setResendDisabled(true);
        resendOTP();
    };

    // const handleGoBack = () => {
    //     displayOTP(!isOTPReady);
    //     clearPhoneNumber();
    // };

    const [startTime, setStartTime] = useState('');
    useEffect(() => {
        const time = dayjs();
        setStartTime(time.toISOString());
    }, []);

    return (
        <>
            <TimeTracker
                stage={ISurveyScanStageEnum.BondStOtp}
            />
            <div style={{ ...ContainerStyles.mainContainer }}>
                <div style={{
                    textAlign: 'start',
                    flexWrap: 'wrap',
                    color: 'white',
                    width: '100%',
                    margin: '40px 0px',
                }}
                >
                    <div style={{ fontSize: '20px', fontWeight: 'bold', color: 'lightgreen' }}>
                        {Translate.t('otpHeader')}
                    </div>
                    <div style={{ fontSize: '14px' }}>
                        {Translate.t('bondOtpMessage1').replace(OTPLOV.phoneNumber, phoneNumber)}
                    </div>

                    {/* <Button onClick={handleGoBack} style={{ marginTop: '20px' }}>
                    Go Back
                </Button> */}

                    <div style={{ margin: '40px auto auto', display: 'flex', gap: '10px', alignItems: 'center' }}>
                        <div style={{ fontSize: '14px', marginBottom: '10px', color: 'white' }}>
                            {Translate.t('otpCodeInputLabel')}
                        </div>
                        <div style={{ display: 'flex' }}>
                            <div style={{ flexGrow: 2 }}>
                                <Input
                                    type='tel'
                                    disabled={submittingOTP}
                                    value={code}
                                    maxLength={6}
                                    onChange={(e) => {
                                        resetSubmitOTP();
                                        if (!Number.isNaN(Number(e.currentTarget.value)) && !e.currentTarget.value.includes(' ')) {
                                            setCode(e.currentTarget.value);
                                        }
                                    }}
                                    style={{
                                        paddingLeft: '15px',
                                        borderRadius: '0px',
                                        height: '60px',
                                        fontSize: '18px',
                                        letterSpacing: '5px',
                                        opacity: submittingOTP ? 0.5 : 1.0,
                                        cursor: submittingOTP ? 'not-allowed' : 'text',
                                    }}
                                />
                            </div>
                            <Button
                                disabled={resendDisabled || submittingOTP}
                                onClick={handleResendOTPClick}
                                style={{
                                    paddingRight: '15px',
                                    height: '60px',
                                    borderRadius: '0px',
                                    fontSize: '10px',
                                    fontWeight: 'bold',
                                    color: resendDisabled ? 'rgb(25, 155, 225, 0.5)' : 'rgb(25, 155, 225, 1.0)',
                                    cursor: resendDisabled ? 'not-allowed' : 'pointer',
                                    width: 'auto',
                                }}
                            >
                                {resendingOTP
                                    ? (
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <Spinner size='sm' />
                                        </div>
                                    )
                                    : `${Translate.t('otpResendOtpButton')} ${resendDisabled ? `00:${timer}` : ''}`}
                            </Button>
                        </div>
                    </div>
                </div>

                <div style={{ marginBottom: '60px' }}>
                    <Button
                        disabled={submittingOTP || code.length !== 6}
                        onClick={() => handleProceedClick()}
                        style={{
                            ...ItemStyles.ProceedButton,
                            opacity: code && code.length === 6 ? 1 : 0.5,
                            cursor: code && code.length === 6 ? 'pointer' : 'not-allowed',
                            textAlign: 'center',
                            color: 'white',
                        }}
                    >
                        {submittingOTP ? <Spinner /> : Translate.t('otpNextButton')}
                    </Button>

                    {/* <Text style={{ ...ItemStyles.ErrorMessage, height: '60px' }}>{submitOTPError}</Text> */}
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    submittingOTP: Selectors.homeGetSubmitOTPAttempting(state),
    submitOTPError: Selectors.homeGetSubmitOTPError(state),
    resendingOTP: Selectors.homeGetResendOTPAttempting(state),
    phoneNumber: Selectors.homeGetPhoneNumber(state),
    isOTPReady: Selectors.homeIsOTPScreenReady(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    submitOTP: (params: ISubmitOTPParams) => dispatch(Actions.homeSubmitOTPAttempt(params)),
    resendOTP: () => dispatch(Actions.homeResendOTPAttempt()),
    resetSubmitOTP: () => dispatch(Actions.homeResetSubmitOTP()),
    displayOTP: (param: boolean) => dispatch(Actions.homeDisplayOTPScreen(param)),
    clearPhoneNumber: () => dispatch(Actions.homeHardResetPhoneNumber()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BondOTP);
