import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { Spinner } from 'reactstrap';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { IEthnicityEnum, IFlavorEnum, IGenderEnum, INicotineBrandEnum, IProfessionEnum, IStateEnum, ISticksSmokedEnum, ISurveyScanStageEnum, LanguageSelectionEnum } from 'api/HomeBase';

import Text from 'components/Text';
import Button from 'components/Button';
import ToggleButton from 'components/ToggleButton';

import LOV from 'lib/LOVs/BasicInfo';
import NavActions from 'lib/NavActions';

import Selectors from 'redux/Selectors';
import Actions from 'redux/Actions';
import { AppDispatch, RootState } from 'redux/store';
import { ISubmitBasicInfoAndSurveyStatusParams } from 'redux/slices/home/types';

import Translate from 'translate/Translate';

import TimeTracker from 'components/TimeTracker';
import dayjs from 'dayjs';
import { ItemStyles } from '../../home/bond/styles';
import MultipleToggleAnswerStyles from '../../surveys/questions/styles/MultipleToggleAnswerStyles';
import { ContainerStyles as DropdownContainerStyles, ItemStyles as DropdownItemStyles } from '../../surveys/questions/styles/DropdownStyles';

interface BasicInfoAndSurveyStatusProps {
    submittingBasicInfoAndSurveyStatus: boolean;
    submitBasicInfoAndSurveyStatusError: string;
    phoneNumber: string;
    dob: string;
    submitBasicInfoAndSurveyStatus: (params: ISubmitBasicInfoAndSurveyStatusParams) => void;
    showIsFirstTimeBuyerOption: boolean;
    qrId: string;
    phoneNumberRepeated: boolean;
    surveyFlavor: string;
    selectedLanguage: LanguageSelectionEnum;
    displayOTPScreen: (param:boolean) => void;
}

type OptionType = { [key: string]: any };

const BasicInfoAndSurveyStatus = ({
    submittingBasicInfoAndSurveyStatus,
    submitBasicInfoAndSurveyStatusError,
    phoneNumber,
    dob,
    submitBasicInfoAndSurveyStatus,
    showIsFirstTimeBuyerOption,
    qrId,
    phoneNumberRepeated,
    surveyFlavor,
    selectedLanguage,
    displayOTPScreen,
}: BasicInfoAndSurveyStatusProps): JSX.Element => {
    const [gender, setGender] = useState<IGenderEnum>();
    const [residingState, setResidingState] = useState<IStateEnum>();
    const [ethnicity, setEthnicity] = useState<IEthnicityEnum>();
    const [profession, setProfession] = useState<IProfessionEnum>(IProfessionEnum.NotBlockedProfessions);
    const [nicotineBrand, setNicotineBrand] = useState<INicotineBrandEnum>();
    const [flavor, setFlavor] = useState<IFlavorEnum>();
    const [sticksSmoked, setSticksSmoked] = useState<ISticksSmokedEnum>();
    const [currentlyBreastfeeding, setCurrentlyBreastfeeding] = useState<boolean>();
    const [firstTimeBuyer, setFirstTimeBuyer] = useState<boolean>();

    const [stateOptions, setStateOptions] = useState<OptionType[]>([]);
    const [professionOptions, setProfessionOptions] = useState<OptionType[]>([]);
    const [nicotineBrandOptions, setNicotineBrandOptions] = useState<OptionType[]>([]);
    const [nicotineFlavorOptions, setNicotineFlavorOptions] = useState<OptionType[]>([]);
    const [sticksSmokedOptions, setSticksSmokedOptions] = useState<OptionType[]>([]);

    let flavorSmoked;
    let firstTimeBuyerPlaceholder: string;
    if ((qrId === '649292432a3830ba748bd04b') || (qrId === '64785b7876bf5158815366be')) {
        firstTimeBuyerPlaceholder = Translate.t('bondBasicInfoFirstTimeBuyerInputLabelSS');
        flavorSmoked = Translate.t('basicInfoNicotineFlavorInputLabel2');
    } else if ((qrId === '649260c77659192a17d4cb96') || (qrId === '64785b8b76bf5158815366cf')) {
        firstTimeBuyerPlaceholder = Translate.t('bondBasicInfoFirstTimeBuyerInputLabelTS');
        flavorSmoked = Translate.t('basicInfoNicotineFlavorInputLabel2');
    } else {
        if (surveyFlavor) {
            firstTimeBuyerPlaceholder = `${Translate.t('bondBasicInfoFirstTimeBuyerInputLabelGeneric')} ${surveyFlavor}?`;
        } else {
            firstTimeBuyerPlaceholder = `${Translate.t('bondBasicInfoFirstTimeBuyerInputLabelGeneric')}?`;
        }
        flavorSmoked = Translate.t('basicInfoNicotineFlavorInputLabel');
    }

    const isSubmitButtonDisabled = !nicotineBrand;

    const handleProceedClick = () => {
        if (nicotineBrand) {
            submitBasicInfoAndSurveyStatus({
                gender: IGenderEnum.NotSet,
                state: IStateEnum.NotSet,
                ethnicity: IEthnicityEnum.NotSet,
                profession,
                nicotineBrand,
                smokeFlavour: flavor,
                sticksSmoked: sticksSmoked ?? ISticksSmokedEnum.MoreThanOnePerWeek,
                isTrialist: true, // Fixed to True (All surveys are now trialist, no longer repeated)
                stage: ISurveyScanStageEnum.BondStBasicInfo,
                startTime,
            });
        } else {
            toast.error(Translate.t('surveyQuestionNoAnswerToast'), {
                toastId: 'basic-info-error-toast',
            });
        }
    };

    useEffect(() => {
        ReactGA.gtag('event', 'page_view', {
            page_title: window.location.pathname,
            page_location: window.location.pathname,
        });
        setSticksSmoked(ISticksSmokedEnum.MoreThanOnePerWeek);
        setCurrentlyBreastfeeding(false);
        if (!showIsFirstTimeBuyerOption) {
            setFirstTimeBuyer(true);
        }
        if (phoneNumberRepeated) {
            setFirstTimeBuyer(false);
        }
        window.scrollTo(0, 0);

        const handleGoBackEvent = (ev: PopStateEvent) => {
            ev.preventDefault();

            const goBackToOtpScreenPromptError = Translate.t('goBackToOtpPromptMessage');
            const confirm = window.confirm(goBackToOtpScreenPromptError);
            if (confirm) {
                NavActions.navReplace('/b/v2/otp');
            } else {
                NavActions.navReplace('/v2/survey');
            }
        };

        window.addEventListener('popstate', handleGoBackEvent);

        return () => {
            window.removeEventListener('popstate', handleGoBackEvent);
        };
    }, []);

    useEffect(() => {
        const stateList = [];
        for (let i = 0; i < Object.keys(IStateEnum).filter(item => Number.isNaN(Number(item))).length; i += 1) {
            stateList.push({ label: Translate.t(`basicInfoResidingState${IStateEnum[i + 1]}`), value: i + 1 });
        }

        if (stateList.length) {
            setStateOptions([...stateOptions, ...stateList]);
        }
    }, [IStateEnum]);

    useEffect(() => {
        const professionList = Object.keys(IProfessionEnum).filter(key => Number.isNaN(Number(key))).map((item: string) => {
            return { label: Translate.t(`basicInfoProfession${item}`), value: IProfessionEnum[item as keyof typeof IProfessionEnum] };
        });

        if (professionList.length) {
            setProfessionOptions([...professionOptions, ...professionList]);
        }
    }, [IProfessionEnum]);

    useEffect(() => {
        const nicotineBrandList = [];
        for (let i = 0; i < LOV.NicotineBrandLOV.length; i += 1) {
            const trimmedKey = LOV.NicotineBrandLOV[i]?.replace(/\s+/g, '').replace(/&+/g, 'And');
            if (trimmedKey === INicotineBrandEnum[i + 1]) {
                nicotineBrandList.push({ label: LOV.NicotineBrandLOV[i], value: i + 1 });
            } else {
                for (let index = 0; index < Object.keys(INicotineBrandEnum).filter(item => Number.isNaN(Number(item))).length; index += 1) {
                    if (trimmedKey === INicotineBrandEnum[index + 1]) {
                        nicotineBrandList.push({ label: LOV.NicotineBrandLOV[i], value: index + 1 });
                    }
                }
            }

            if (nicotineBrandList.length) {
                setNicotineBrandOptions([...nicotineBrandOptions, ...nicotineBrandList]);
            }
        }
    }, [LOV.NicotineBrandLOV.length]);

    useEffect(() => {
        const nicotineFlavorList: OptionType[] = [];
        const nicotineFlavorIndex = Object.values(IFlavorEnum).filter(item => !Number.isNaN(Number(item)));
        if (nicotineBrand) {
            const currentBrand = LOV.NicotineFlavorLOV.find(item => item.brand === INicotineBrandEnum[nicotineBrand]);
            if (currentBrand?.flavour) {
                for (let i = 0; i < currentBrand?.flavour?.length; i += 1) {
                    const trimmedKey = currentBrand.flavour[i].replace(/\s+/g, '').replace(/&+/g, 'And');
                    Object.keys(IFlavorEnum).filter(item => Number.isNaN(Number(item))).find((item, index) => {
                        if (item === trimmedKey) {
                            nicotineFlavorList.push({ label: currentBrand.flavour[i], value: nicotineFlavorIndex[index] });
                        } return false;
                    });
                }
            }

            if (nicotineFlavorList.length) {
                setNicotineFlavorOptions(nicotineFlavorList);
            }
        }
    }, [LOV.NicotineFlavorLOV.length, nicotineBrand]);

    useEffect(() => {
        const sticksSmokedList = [];
        for (let i = 10; i < Object.keys(ISticksSmokedEnum).filter(item => Number.isNaN(Number(item))).length + 10; i += 1) {
            sticksSmokedList.push({ label: Translate.t(`basicInfoSticksSmoked${ISticksSmokedEnum[i + 1]}`), value: i + 1 });
        }

        if (sticksSmokedList.length) {
            setSticksSmokedOptions([...sticksSmokedOptions, ...sticksSmokedList]);
        }
    }, [ISticksSmokedEnum]);

    const renderBody = () => {
        const { selected, nonSelected } = MultipleToggleAnswerStyles;
        const { CigarretteBrandsV2LOV } = LOV;
        // V2 - no longer uses all brand options but display 6 brands & others
        return CigarretteBrandsV2LOV.map((item, idx) => {
            const isSelected = item.value === nicotineBrand;

            // Massaged others label as they have different translations based on selected language
            const otherBrandsValue = CigarretteBrandsV2LOV.find(i => i.value === INicotineBrandEnum.Others);
            if (item.value === otherBrandsValue?.value) {
                return (
                    <ToggleButton
                        key={`nicotine-brand-${item.value}-${Math.random()}`}
                        isSelected={isSelected}
                        style={isSelected ? selected : nonSelected}
                        onClick={() => {
                            setFlavor(undefined);
                            setNicotineBrand(item.value);
                        }}
                    >
                        {Translate.t('otherBrandsLabel')}
                    </ToggleButton>
                );
            }

            return (
                <ToggleButton
                    key={`nicotine-brand-${item.value}-${Math.random()}`}
                    isSelected={isSelected}
                    style={isSelected ? selected : nonSelected}
                    onClick={() => {
                        setFlavor(undefined);
                        setNicotineBrand(item.value);
                    }}
                >
                    {item.label}
                </ToggleButton>
            );
        });
    };

    const [startTime, setStartTime] = useState('');
    useEffect(() => {
        const time = dayjs();
        setStartTime(time.toISOString());
    }, []);

    return (
        <>
            <TimeTracker
                stage={ISurveyScanStageEnum.BondStBasicInfo}
            />
            <div>
                <div style={DropdownContainerStyles.mainContainer}>
                    <div style={DropdownContainerStyles.questionContainer}>
                        <Text css={DropdownItemStyles.question}>
                            {`1. ${Translate.t('basicInfoNicotineBrandInputLabel')}`}
                        </Text>
                    </div>
                    <ButtonContainer>
                        {renderBody()}
                    </ButtonContainer>
                </div>

                <Footer>
                    <NextButtonContainer>
                        <NextButton onClick={() => handleProceedClick()}>
                            {submittingBasicInfoAndSurveyStatus ? <Spinner /> : Translate.t('surveyNextText')}
                        </NextButton>
                    </NextButtonContainer>

                    <Text style={{ ...ItemStyles.ErrorMessage, height: '60px' }}>{submitBasicInfoAndSurveyStatusError}</Text>
                </Footer>
            </div>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    submittingBasicInfoAndSurveyStatus: Selectors.homeGetSubmitBasicInfoAndSurveyStatusAttempting(state),
    submitBasicInfoAndSurveyStatusError: Selectors.homeGetSubmitBasicInfoAndSurveyStatusError(state),
    phoneNumber: Selectors.homeGetPhoneNumber(state),
    dob: Selectors.homeGetDob(state),
    showIsFirstTimeBuyerOption: Selectors.homeGetScanInfoShowIsFirstTimeBuyerOption(state),
    qrId: Selectors.homeGetQrId(state),
    phoneNumberRepeated: Selectors.homeGetScanInfoPhoneNumberRepeated(state),
    surveyFlavor: Selectors.homeGetScanInfoSurveyFlavor(state),
    selectedLanguage: Selectors.homeGetSelectedLanguage(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    submitBasicInfoAndSurveyStatus: (params: ISubmitBasicInfoAndSurveyStatusParams) => dispatch(Actions.homeSubmitBasicInfoAndSurveyStatusAttempt(params)),
    displayOTPScreen: (param: boolean) => dispatch(Actions.homeDisplayOTPScreen(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BasicInfoAndSurveyStatus);

const Footer = styled.div`
    marginBottom: 40px
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 10px;
    flex-wrap: wrap;
    gap: 10px;
`;

const NextButton = styled(Button)`
    background-color: #fff;
    border-radius: 0px;
    font-size: 16px;
`;

const NextButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;
